import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function impersonalEmailValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const isImpersonalEmail = control.value.includes('admin')
			|| control.value.includes('invoice')
			|| control.value.includes('info')
			|| control.value.includes('receptie')
			|| control.value.includes('reception');

    return isImpersonalEmail ? { impersonalEmail: true } : null;
  };
}
