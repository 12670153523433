<ct-platforms-chip-container>
  <ct-platform-chip
    *ngFor='let platform of platforms'
    [class.platform-manager]='platform.isManager'
    [class]='platform.name.toLowerCase() + "-background-color"'
  >
    {{ rsSkinPrefix + "." + platform.name | translate }}

    <ng-container *ngIf='platform.isManager'>
      <mat-icon
        data-testid='platform-chip-king-mat-icon'
        svgIcon='king'
      ></mat-icon>
    </ng-container>
  </ct-platform-chip>
</ct-platforms-chip-container>