import { MgtEnvironment } from '@environments/mgt-environment';
import { RentaDomains } from '@lib/rs-ngx';

export const environment: MgtEnvironment = {
  production: false,
  apiUrl: `https://api.my.tst.${RentaDomains.RENTA}`,
  apiUrlNotifications: `https://notification.tst.${RentaDomains.RENTA}/api`,
  apiUrlMail: `https://api.mail.tst.${RentaDomains.RENTA}`,
  useMockedServices: false,
  enableSentryMonitoring: true,
  sentryReleaseName: 'test-mgt-12775',
  environment: 'test',
  authUrl: `https://authentication.tst.${RentaDomains.RENTA}/`,
  topBarCdn: `https://cdn.${RentaDomains.RENTA}/topbar/tst/v1.3/`,
  topBarEnvironment: 'tst',
  weblateEnvironment: 'tst',
  appName: 'MGT',
};
const environmentShort = 'tst';

export const environmentMobi: Partial<MgtEnvironment> = {
  apiUrl: `https://api.view.${environmentShort}.${RentaDomains.EUROPE}`,
  apiUrlNotifications: `https://notification.${environmentShort}.${RentaDomains.EUROPE}/api`,
  apiUrlMail: `https://api.mail.${environmentShort}.${RentaDomains.EUROPE}`,
  authUrl: `https://authentication.${environmentShort}.${RentaDomains.EUROPE}/`,
  appName: 'Mobiview',
};
